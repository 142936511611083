<template>
  <TransitionRoot appear :show="modalIsOpen" as="template">
    <Dialog as="div" @close="modalLogin.closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="flex items-center justify-center h-full px-8 text-center">
          <DialogOverlay
            class="fixed inset-0 flex items-center justify-center bg-gray-900 opacity-50"
          />

          <span class="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="transition-opacity duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              class="relative inline-block w-full max-w-xl bg-white shadow-xl organism modal-login transition-all transform"
            >
              <OrganismsTabbedContent :organism="tabbedContent">
                <template v-for="item in tabbedContent.item" #[item.id]>
                  <FormsDynamicForm
                    :schema="item.formSchema"
                    :id="item.id"
                    class="py-8"
                    @form-submit="handleFormSubmit"
                    :submitting="loginSubmitting || registerSubmitting"
                    :key="item.id"
                    data-hs-ignore="true"
                  />
                </template>
              </OrganismsTabbedContent>

              <button
                type="button"
                class="absolute z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full -top-3 -right-3"
                @click="modalLogin.closeModal"
              >
                <i
                  class="block text-4xl text-bc-dark-blue relative top-[3px] icon icon-exit-circle"
                ></i>
              </button>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style>
.organism.modal-login {
  .tabbed-content {
    header {
      background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
      .header-content {
        @apply max-w-[556px] sm:px-0 mb-8;
      }
    }
    .tabs-component-tabs {
      @apply w-[556px] mx-auto;
    }
  }
}
</style>

<script setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
const modalLogin = useModalLogin();
const { modalIsOpen, modalTargetTab } = storeToRefs(modalLogin);
import * as Yup from "yup";
const favoritesAuth = useFavoritesAuth();
const { isLoggedIn, user, userCookie, refreshTokenCookie } =
  storeToRefs(favoritesAuth);
import { LOGIN_USER, REGISTER_USER } from "~/graphql/LoginRegisterUser";
import { useMutation } from "@vue/apollo-composable";
const { onLogin, onLogout } = useApollo();
const favorites = useFavorites();

const errors = ref([]);
const errorMessage = ref("");

const tabbedContent = computed(() => {
  const targetTabHash = modalTargetTab?.value ?? "login";

  return {
    title1: "Log in to save your favorites",
    description:
      "To save your favorite floor plans to your Favorites page, please either log in or create an account.",
    item: [
      {
        id: "login",
        name: "Login",
        content: "",
        formSchema: {
          id: "login",
          submit: {
            buttonText: "Login",
          },
          fields: [
            {
              label: "Email",
              name: "email",
              as: "input",
              id: "login-email",
              autocomplete: "email",
              rules: Yup.string()
                .email("This must be a valid email")
                .required("Email is a required field"),
            },
            {
              label: "Password",
              name: "password",
              type: "password",
              as: "input",
              id: "login-password",
              autocomplete: "current-password",
              rules: Yup.string().required("Password Confirmation is required"),
            },
          ],
        },
      },
      {
        id: "create-account",
        name: "Create Account",
        content: "",
        formSchema: {
          id: "create-account",
          submit: {
            buttonText: "Create Account",
          },
          fields: [
            {
              type: "fieldset",
              name: "name",
              fields: [
                {
                  label: "First Name",
                  name: "firstName",
                  as: "input",
                  id: "create-account-first-name",
                  autocomplete: "given-name",
                  rules: Yup.string().required("First Name is required"),
                },
                {
                  label: "Last Name",
                  name: "lastName",
                  as: "input",
                  id: "create-account-last-name",
                  autocomplete: "family-name",
                  rules: Yup.string().required("Last Name is required"),
                },
              ],
            },
            {
              label: "Email",
              name: "email",
              as: "input",
              id: "create-account-email",
              autocomplete: "email",
              rules: Yup.string()
                .email("This must be a valid email")
                .required("Email is a required field"),
            },
            {
              label: "Password",
              name: "password",
              as: "input",
              type: "password",
              id: "create-account-password",
              autocomplete: "new-password",
              rules: Yup.string()
                .min(6, "Password must be at least 6 characters")
                .required("Password is required"),
            },
            {
              label: "Password Confirmation",
              name: "passwordConfirmation",
              as: "input",
              type: "password",
              id: "create-account-password-confirmation",
              autocomplete: "new-password",
              rules: Yup.string().required("Password Confirmation is required"),
            },
          ],
        },
      },
    ],
    defaultTabHash: "login",
    targetTabHash: targetTabHash,
  };
});

const {
  mutate: loginMutate,
  loading: loginSubmitting,
  error: loginError,
  called: loginCalled,
  onDone: loginOnDone,
  onError: loginOnError,
} = useMutation(LOGIN_USER);

const login = async (email, password) => {
  favoritesAuth.logout();

  return new Promise((resolve, reject) => {
    loginMutate({
      username: email,
      password: password,
    });

    loginOnDone((result) => {
      userCookie.value = {
        firstName: result?.data?.login?.user?.firstName ?? "",
        userId: result?.data?.login?.user?.databaseId ?? -1,
        favorites: isValidJSON(result?.data?.login?.user?.favorites)
          ? result?.data?.login?.user?.favorites
          : JSON.stringify([]),
      };

      isLoggedIn.value = true;

      onLogin(result?.data?.login?.authToken);
      refreshTokenCookie.value = result?.data?.login?.refreshToken;
      resolve();
    });

    loginOnError((error) => {
      console.error(error);
      errorMessage.value =
        "Sorry, we were not able to log you in with that email & password combination.";
      reject(error);
    });
  });
};

const {
  mutate: registerMutate,
  loading: registerSubmitting,
  error: registerError,
  called: registerCalled,
  onDone: registerOnDone,
  onError: registerOnError,
} = useMutation(REGISTER_USER);

const register = async (firstName, lastName, email, password) => {
  favoritesAuth.logout();

  const sanitizedFirstName = firstName.toLowerCase().replace(/[^a-z0-9]/g, "");
  const sanitizedLastName = lastName.toLowerCase().replace(/[^a-z0-9]/g, "");
  const username =
    sanitizedFirstName +
    "_" +
    sanitizedLastName +
    generateRandomString(4, "0123456789");

  return new Promise((resolve, reject) => {
    registerMutate({
      username: username,
      password: password,
      email: email,
      firstName: firstName,
      lastName: lastName,
    });

    registerOnDone((result) => {
      userCookie.value = {
        firstName: result?.data?.registerUser?.user?.firstName ?? "",
        userId: result?.data?.registerUser?.user?.databaseId ?? -1,
        favorites: isValidJSON(result?.data?.registerUser?.user?.favorites)
          ? result?.data?.registerUser?.user?.favorites
          : JSON.stringify([]),
      };

      isLoggedIn.value = true;

      onLogin(result?.data?.registerUser?.user?.jwtAuthToken);
      refreshTokenCookie.value =
        result?.data?.registerUser?.user?.jwtRefreshToken;
      resolve();
    });

    registerOnError((error) => {
      console.error(error);
      errorMessage.value =
        "Sorry, we were not able to register you at this time.";
      reject(error);
    });
  });
};

const setPreLoginFavorite = () => {
  const itemString = localStorage.getItem("preLoginFavoriteItem");
  if (itemString) {
    try {
      const item = JSON.parse(itemString);
      favorites.toggleFavoriteProduct(item);
    } catch (toggleError) {
      console.error(
        "An error occurred setting the preLoginFavoriteItem:",
        toggleError,
      );
    } finally {
      localStorage.removeItem("preLoginFavoriteItem");
    }
  }
};

const handleFormSubmit = async (values) => {
  const manualErrorMessage = document.querySelector(
    "form#" + values.formId + " #manual-error-message",
  );
  manualErrorMessage.innerHTML = "";

  switch (values.formId) {
    case "create-account":
      if (values.password !== values.passwordConfirmation) {
        manualErrorMessage.innerHTML = "Passwords must match";
        return;
      }
      try {
        await register(
          values.firstName,
          values.lastName,
          values.email,
          values.password,
        );

        setPreLoginFavorite();

        if (isLoggedIn.value) {
          modalLogin.closeModal();
        } else {
          manualErrorMessage.innerHTML = errorMessage.value;
        }
      } catch (error) {
        console.error("An error occurred during registration:", error);
        manualErrorMessage.innerHTML = errorMessage.value;
      }
      break;
    case "login":
      try {
        await login(values.email, values.password);

        setPreLoginFavorite();

        if (isLoggedIn.value) {
          modalLogin.closeModal();
        } else {
          manualErrorMessage.innerHTML = errorMessage.value;
        }
      } catch (error) {
        console.error("An error occurred during login:", error);
        manualErrorMessage.innerHTML = errorMessage.value;
      }
      break;
  }
};
</script>
